import { AppBar, Typography } from "@mui/material";

function Footer() {
    return (
      <>
        <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, boxShadow: 0 }}>
            <Typography align="right" variant="body2" p={1}>copyright &#169; 2025</Typography>
        </AppBar>
      </>
    );
  }
  export default Footer;